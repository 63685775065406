<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 1118 264.507"
  >
    <path
      fill="currentColor"
      d="M1118 264.507H0A264.073 264.073 0 0 1 13.483 228.6a201.175 201.175 0 0 1 16.583-29.2 164.467 164.467 0 0 1 19.156-23.077 147.636 147.636 0 0 1 21.218-17.551c26.2-17.788 58.174-26.807 95.034-26.807a266.374 266.374 0 0 1 49.594 4.931 341.842 341.842 0 0 1 41.916 10.866 647.913 647.913 0 0 1 51.916-41.055 612.038 612.038 0 0 1 55.23-34.949 581.831 581.831 0 0 1 58.243-28.464 560.109 560.109 0 0 1 60.957-21.6A546.89 546.89 0 0 1 558.676 5.37a530.477 530.477 0 0 1 143.65-.947 543.013 543.013 0 0 1 67.187 13.1 561.071 561.071 0 0 1 65.479 21.536 586.731 586.731 0 0 1 63.234 29.719 618.407 618.407 0 0 1 60.453 37.652 655.64 655.64 0 0 1 57.139 45.338 698.125 698.125 0 0 1 53.284 52.774A744.913 744.913 0 0 1 1118 264.5Z"
    />
  </svg>
</template>
